import React from "react"
import { Container } from "react-bootstrap"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { navigate } from "gatsby"

import { useUrlPrefix } from "../../utils"
import { APREMIA_EMAIL, APREMIA_PHONE, APREMIA_PHONE_FORMATTED, APREMIA_WHATSAPP, APREMIA_WHATSAPP_CLEAN } from "../../params"

import LogoWhite from "../../images/logo-white.svg"
import IconMailImage from "../../images/icon-mail.svg"
import IconCallImage from "../../images/icon-call.svg"
import IconWhatsappImage from "../../images/icon-whatsapp.svg"
import IconInstagramImage from "../../images/icon-instagram.svg"
import IconFacebookImage from "../../images/icon-facebook.svg"
import IconTwitterImage from "../../images/icon-twitter.svg"

const FooterStyled = styled.footer`
  background-color: ${props => props.theme.colors["body-color"]};
  color: #ffffff;
`

const Icon = styled.svg`
  fill: #ffffff;
  height: 24px;
`

const Separator = styled.div`
  height: 120px;
  width: 0;
  border-left: 1px solid ${props => props.theme.colors["light-blue"]};
  opacity: .25;
  margin: 0 -11rem;
  @media (max-width: 767px) {
    height: 0;
    width: 100%;
    border-left: 0 none;
    border-top: 1px solid ${props => props.theme.colors["light-blue"]};
    margin: 1.5rem 0;
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const urlPrefix = useUrlPrefix()

  const gotoQuoteForm = (event) => {
    event.preventDefault()
    const form = document.getElementById("quote-form")
    if (form !== null) {
      form.scrollIntoView();
    } else {
      navigate(`${urlPrefix}/#quote-form`)
    }
  }

  const links = [
    {
      title: t("about_us"),
      href: "/about-us/",
    },
    // {
    //   title: t("blog"),
    //   href: "#",
    // },
    {
      title: t("contact_us"),
      href: "/contact-us/",
    },
  ]

  return (
    <FooterStyled className="py-4 py-md-6">
      <Container className="gx-0 gx-md-3">
        <div className="d-none d-md-block text-center mb-5">
          <LogoWhite />
        </div>

        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-around">
          <div className="menu menu-dark menu-footer-nav equalize-padding-x">
            {links.map((item, index) => (
              <Link to={item.href} key={index} onClick={item.onClick}>
                {item.title}
              </Link>
            ))}
          </div>
          <Separator />
          <div className="menu-icon menu-dark equalize-padding-x">
            <a href={`mailto:${APREMIA_EMAIL}`}>
              <Icon as={IconMailImage} /> {APREMIA_EMAIL}
            </a>
            <a href={`tel:${APREMIA_PHONE}`}>
              <Icon as={IconCallImage} /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href={`https://wa.me/${APREMIA_WHATSAPP_CLEAN}`}>
              <Icon as={IconWhatsappImage} /> WhatsApp
            </a>
          </div>
          <Separator />
          <div className="menu-icon menu-dark equalize-padding-x">
            <a href="https://www.instagram.com/apremiainc" target="_blank" rel="noopener noreferrer">
              <Icon as={IconInstagramImage} /> Instagram
            </a>
            <a href="https://www.facebook.com/apremiainsurance" target="_blank" rel="noopener noreferrer">
              <Icon as={IconFacebookImage} /> Facebook
            </a>
            {/* <a href="#">
              <Icon as={IconTwitterImage} /> Twitter
            </a> */}
          </div>
          <Separator className="d-md-none" />
        </div>

        <div className="text-md-center mt-md-5 equalize-padding-x">
          <LogoWhite className="d-md-none mb-3" />
          <br />
          <span className="d-none d-md-block fw-bold opacity-25">
            {t("copyright")} Apremia © {new Date().getFullYear()}
          </span>
          <span className="d-md-none fw-bold opacity-25 small">
            {t("copyright")} Apremia © {new Date().getFullYear()}
          </span>
        </div>
      </Container>
    </FooterStyled>
  )
}

export default Footer
